// Step 1: Import React
import * as React from 'react'
import { Link } from 'gatsby'
import Layout2 from '../components/layout2'
import { StaticImage } from 'gatsby-plugin-image'
import Seo2 from '../components/seo2'

// Step 2: Define your component
const IndexPage = () => {
  return (
    <Layout2 pageTitle="Contact Me">
      <p>Have a question or a comment? Send me an email!</p></Layout2>
  )
}

// You'll learn about this in the next task, just copy it for now
export const Head = () => <Seo2 title="Contact" />

// Step 3: Export your component
export default IndexPage